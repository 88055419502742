import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container, Grid } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import RealWeddingCard from "../../../components/src/RealWeddingCard.web";

// Customizable Area End

import RealWeddingController, { Props } from "./RealWeddingController";

export default class RealWedding extends RealWeddingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {realWeddingData, realWeddingProfileData} = this.state
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <RealWeddingStyle component="section" id="real-wedding">
            <Typography className="header-real-wedding" component="h3">
             {realWeddingData.attributes.title}
            </Typography>
            <Typography className="description-real-wedding">
            {realWeddingData.attributes.description}
            </Typography>
            <Typography className="real-wedding-total-result">
              Showing {realWeddingProfileData.data.length} results{" "}
            </Typography>

            <Grid container spacing={4} className="inspiration-item-container">
              {realWeddingProfileData.data.map((data:any, index:number) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                  <RealWeddingCard
                    data-test-id="real-wedding-item"
                    onCardClick={this.redirectToRealWeddingItemsPage}
                    couple_name={data.attributes.title}
                    id={data.id}
                    location={data.attributes.description}
                    image_url={[data.attributes.media[0].url]}
                  />
                </Grid>
              ))}
            </Grid>
          </RealWeddingStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const RealWeddingStyle = styled(Box)({
  padding: "32px 0 24px",
  "& .header-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .description-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "16px 0 64px",
  },
  "& .real-wedding-total-result": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    color: "#44403C",
  },
  "& .inspiration-item-container": {
    marginTop: "-8px",
    "& > .MuiGrid-item": {
      width: "100%",
    },
  },
});

// Customizable Area End
