// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled } from "@mui/material";

interface Props {}

interface State {}

export default class StatSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <StatsContainer component="section" id="stats-container">
        <Box className="starts-main-container">
          <Box className="stats-container">
            <Box className="stat-item">
              <Typography component="h2">15+</Typography>
              <Typography>Experience in wedding industry</Typography>
            </Box>
            <Box className="divider-1">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">1500+</Typography>
              <Typography>No. of wedding done</Typography>
            </Box>
            <Box className="divider-2">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">1500+</Typography>
              <Typography>No. of venues done</Typography>
            </Box>
            <Box className="divider-3">
              <Box className="divider" />
            </Box>
            <Box className="stat-item">
              <Typography component="h2">180+</Typography>
              <Typography>No. of trusted vendors worked</Typography>
            </Box>
            <Box className="divider-4">
              <Box className="divider" />
            </Box>
            <Box className="stat-item stat-item-fix-length">
              <Typography component="h2">100+</Typography>
              <Typography>Wedding Vendors</Typography>
            </Box>
          </Box>
        </Box>
      </StatsContainer>
    );
  }
}

const StatsContainer = styled(Box)(({ theme }) => ({
  zIndex: "2",
  position: "relative",
  marginTop: "-113px",
  marginBottom: "40px",
  "& .starts-main-container": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .stats-container": {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#ffffff",
    padding: "58px 55px",
    boxShadow: "0px 4px 4px 0px #00000040",
    maxWidth: "1150px",
    boxSizing: "border-box",
    rowGap: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "55px 30px !important",
    },
    "@media(max-width: 700px)": {
      padding: "40px 15px !important",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  "& .stat-item-fix-length": {
    flex: "0 0 176px !important",
    [theme.breakpoints.down("md")]: {
      flex: "1 !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33.33% !important",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
  },
  "& .divider-3": {
    "@media(max-width: 700px)": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  "& .divider-4": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .divider-2": {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .stat-item": {
    textAlign: "center",
    boxSizing: "border-box",
    flex: "1",
    padding: "31px 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "21px 10px !important",
    },
    "@media(max-width: 700px)": {
      padding: "21px 5px !important",
      flex: "0 0 33%",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 49.8% !important",
    },
    "& h2": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
    },
    "& p": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#44403C",
      marginTop: "12px",
    },
  },
  "& .divider": {
    height: "100%",
    width: "1px",
    backgroundColor: "#44403C",
    alignSelf: "center",
  },
}));
// Customizable Area End
