import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import RealWeddingItemCard from "../../../components/src/RealWeddingItemCard.web";

// Customizable Area End

import RealWeddingItemsController, {
  Props,
} from "./RealWeddingItemsController";

export default class RealWeddingItems extends RealWeddingItemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {realWeddingData, realWeddingMahendiData, realWeddingHaldiData, realWeddingBaratData, realWeddingSangitData, realWeddingAlbumData, realWeddingDescriptionData} = this.state
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <RealWeddingItemStyle component="section" id="real-wedding-items">
            <Typography className="header-real-wedding" component="h3">
              {realWeddingData?.attributes?.title}
            </Typography>
            <Typography className="description-real-wedding">
              {realWeddingData?.attributes?.description}
            </Typography>
            <RealWeddingItemCard
              title={realWeddingMahendiData?.attributes?.title}
              imageUrl={[realWeddingMahendiData?.attributes?.image.url]}
              description={realWeddingMahendiData?.attributes?.description}
            />
            <RealWeddingItemCard
              title={realWeddingHaldiData?.attributes?.title}
              imageUrl={[realWeddingHaldiData?.attributes?.image.url]}
              description={realWeddingHaldiData?.attributes?.description}
            />

            <RealWeddingItemCard
              title={realWeddingBaratData?.attributes?.title}
              imageUrl={[realWeddingBaratData?.attributes?.image.url]}
              description={realWeddingBaratData?.attributes.description}
            />
            <RealWeddingItemCard
              title={realWeddingSangitData?.attributes?.title}
              imageUrl={[realWeddingSangitData?.attributes?.image?.url]}
              description={realWeddingSangitData?.attributes?.description}
            />
            <RealWeddingItemCard
              title={realWeddingAlbumData?.attributes?.title}
              imageUrl={[realWeddingAlbumData?.attributes?.album[0]?.url]}
              description={realWeddingAlbumData?.attributes?.description}
            />
            <RealWeddingItemCard
                imageUrl={[realWeddingAlbumData?.attributes?.album[1]?.url,
                  realWeddingAlbumData?.attributes?.album[2]?.url,
                  realWeddingAlbumData?.attributes?.album[3]?.url,
                  realWeddingAlbumData?.attributes?.album[4]?.url,
                realWeddingAlbumData?.attributes?.album[5]?.url]}

/>
            <Box className="real-wedding-items-container">
              <Typography className="real-wedding-item-detail">
                Venues:
                <Typography component="span">
                  {" "}
                  {realWeddingDescriptionData?.attributes?.venue}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
              Wedding Planner:
                <Typography component="span" className="purple-text-color">
                  {" "}
                  Events & Wedding Experiences
                  {realWeddingDescriptionData?.attributes?.wedding_planner}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Decor:
                <Typography component="span">
                  {" "}
                 {realWeddingDescriptionData?.attributes?.decor}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Photography:
                <Typography component="span" className="purple-text-color">
                  {" "}
                  {realWeddingDescriptionData?.attributes?.photography}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Groom’s Outfits:
                <Typography component="span">
                  {" "}
                  {realWeddingDescriptionData?.attributes?.groom_outfit}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Bride’s Outfits:
                <Typography component="span">
                  {" "}
                  {realWeddingDescriptionData?.attributes?.bride_outfits}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Makeup:
                <Typography component="span">{realWeddingDescriptionData?.attributes?.makeup}</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Entertainment:
                <Typography component="span">
                  {" "}
                  {realWeddingDescriptionData?.attributes?.entertainment}
                </Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Encee:
                <Typography component="span"> {realWeddingDescriptionData?.attributes?.encee}</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Choreography:
                <Typography component="span"> {realWeddingDescriptionData?.attributes?.choreography}</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Wedding Invitation:
                <Typography component="span"> {realWeddingDescriptionData?.attributes?.wedding_invitation}</Typography>
              </Typography>
              <Typography className="real-wedding-item-detail">
                Bartenders:
                <Typography component="span"> {realWeddingDescriptionData?.attributes?.bartenders}</Typography>
              </Typography>
            </Box>
          </RealWeddingItemStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const RealWeddingItemStyle = styled(Box)({
  padding: "32px 0 24px",
  "& .header-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "500",
    lineHeight: "50px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .description-real-wedding": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "24px 0 32px",
  },
  "& .real-wedding-items-container": {
    margin: "64px 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .real-wedding-item-detail": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#292524",
    "& span": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#292524",
    },
  },
  "& .purple-text-color": {
    color: "#801188 !important",
  },
});

// Customizable Area End
