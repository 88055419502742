import React from "react";

import {
  Box,InputAdornment,
  Button, Autocomplete, ListItemText,
  InputLabel, Checkbox, Stack, TextField, IconButton,
  Typography, styled, Container, Divider, MenuItem
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Loader from "../../../components/src/AppLoader.web";
import TextInputFiled from "../../../components/src/TextInputField.web";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import SelectField from "../../../components/src/SelectField.web";
const arrowImg = require('../assets/arrow.png')
interface Options {
  value: number;
  label: string;
}
interface RenderOptionProps {
  option: Options;
  selected: boolean;
}
// Customizable Area End

import AddCatalogueFormController, {
  Props, ColorOption
} from './AddCatalogueFormController.web'
const validationSchema = Yup.object().shape({
  productName: Yup.string().required("Product Name is required").trim()
  .matches(/^[a-zA-Z\s]+$/, "Product Name should only contain letters")
  .test(
    "not-only-digits",
    "Product Name cannot contain only digits",
    (value) => {
      return !/^\d+$/.test(value);
    }
  )
  .max(50, "Maximum 50 characters are allowed"),
  skuCode: Yup.string(),
  productCategory: Yup.string().required("Product Category is required"),
  productDescription: Yup.string().required("Product Description is required"),
  fabricDetails: Yup.string(),
  price:Yup.number().required("Price is required"), 
  colorName:Yup.string().required("Color name is required"), 
  availableQuantity:Yup.number().required("Quantity is required"),
  selectColor: Yup.string().required("Please select Coupon"),
  selectFunction: Yup.string().required("Please select Function"),
selectedLocation: Yup.string().required("Please select Location"),
customTitle: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Title should only contain letters")
  .test(
    "not-only-digits",
    "Product Name cannot contain only digits",
    (value) => {
      return !/^\d+$/.test(value);
    }
  )
  .max(50, "Maximum 40 characters are allowed"),
  uploadImage:Yup.string(),
  colorSize:Yup.string(),
  customDescription: Yup.string()
  .max(50, "Maximum 500 characters are allowed"),
  size: Yup.string().required("Please add Size"),
  measurement: Yup.string().required("Please add measurement"),
  selectedFileName: Yup.mixed()
    .test(
      'fileSize',
      'File too large (Max 10MB)',
      value => value ? value.size < 10 * 1024 * 1024 : true
    )
    .test(
      'fileFormat',
      'Unsupported Format (only JPG, PNG)',
      value => value ? ['image/jpeg', 'image/png'].includes(value.type) : true
    ),
    productImages: Yup.mixed()
    .test(
      'fileCount',
      'You can upload a maximum of 10 images',
      (value: File[]) => value && value.length <= 10
    )
    .test(
      'fileSize',
      'Each file should be less than 10MB',
      (value: File[]) => value && value.every(file => file.size <= 10 * 1024 * 1024)
    )
    .test(
      'fileFormat',
      'Unsupported format. Only JPG and PNG files are allowed.',
      (value: File[]) => value && value.every(file => ['image/jpeg', 'image/png'].includes(file.type))
    ),
});

export default class AddCatalogueForm extends AddCatalogueFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };
  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    const coupons = [{ label: 'off500', value: 1 }, { label: "off2000", value: 2 }]
    const functionsList = [{ label: 'Haldi', value: 1 }, { label: "Mahendi", value: 2 }, { label: "Engagement", value: 3 }, { label: "Sangeet", value: 4 }, { label: "Receptions", value: 5 }]
    const locationData = [{ label: 'Agra', value: 1 }, { label: "Mumbai", value: 2 }, { label: "Hyderabad", value: 3 }, { label: "Delhi", value: 4 }, { label: "Jaipur", value: 5 }]

    const productCategory = [{ label: 'Clothes', value: 1 }, { label: 'Electronics', value: 2 }, { label: 'Jewellary', value: 3 }, { label: 'Appliances', value: 4 }]
    const productSize = [{ label: 'XS', value: 1 }, { label: 'Xm', value: 2 }, { label: 'S', value: 3 }, { label: 'XS', value: 4 }]
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <AddCatlogueMainStyle component="section" id="addcatalogueItems">
            <Typography className="addCatlogueText" component="h3">
              Add Catalogue
            </Typography>
            <Divider className="dividerStyle" />
            <Box className="mainCatalogueBox">
              <Typography className="headText">
                Product Details
              </Typography>
              <Formik
                data-test-id="Formik"
                initialValues={this.state.formData}
                validationSchema={validationSchema}
                onSubmit={() => { }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  function setFieldTouched(arg0: string, arg1: boolean) {
                    throw new Error("Function not implemented.");
                  }

                  return (
                    <Form onSubmit={handleSubmit} noValidate>
                      <Box className="mainForm">
                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Name  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="productName"
                              data-test-id="productName-input"
                              placeholder="Enter your Product Name"
                              value={values.productName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.productName, errors.productName)}
                      helperText={this.helperText(
                        touched.productName,
                        errors.productName
                      )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">SKU</InputLabel>
                            <TextInputFiled
                              name="skuCode"
                              data-test-id="skuCode-input"
                              placeholder="R-185"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.skuCode}
                              error={this.isError(touched.skuCode, errors.skuCode)}
                            helperText={this.helperText(
                        touched.skuCode,
                        errors.skuCode
                      )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Category  <span className="star">*</span></InputLabel>
                            <SelectField
                              name="productCategory"
                              data-test-id="monthCheck"
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              options={productCategory}
                              placeholder="Enter your Product Name"
                              label={""} value={values.productCategory} error={Boolean(this.handleErrorText(touched.productCategory, errors.productCategory))}
                              touched={touched.productCategory}
                              helperText={this.handleErrorText(touched.productCategory, errors.productCategory)} />
                          </Box>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Product Description  <span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="productDescription"
                            data-test-id="full-name-input"
                            placeholder="Enter your Product Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.productDescription} error={this.isError(touched.productDescription, errors.productDescription)}
                            helperText={this.helperText(
                        touched.productDescription,
                        errors.productDescription
                      )}
                          />
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Fabric details</InputLabel>
                          <TextInputFiled
                            name="fabricDetails"
                            data-test-id="full-name-input"
                            placeholder="Enter Details of fabric"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.fabricDetails} error={this.isError(touched.fabricDetails, errors.fabricDetails)}
                            helperText={this.helperText(
                        touched.fabricDetails,
                        errors.fabricDetails
                      )}
                          />
                        </Box>

                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Price  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="price"
                              data-test-id="full-name-input"
                              placeholder="Enter Product Price"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.price} error={this.isError(touched.price, errors.price)}
                              helperText={this.helperText(
                          touched.price,
                          errors.price
                        )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available Quantity  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="availableQuantity"
                              data-test-id="full-name-input"
                              placeholder="Enter Available Quantity(in stock)"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.availableQuantity} error={this.isError(touched.availableQuantity, errors.availableQuantity)}
                              helperText={this.helperText(
                          touched.availableQuantity,
                          errors.availableQuantity
                        )}
                            />
                          </Box>

                        </Box>

                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Product Images</InputLabel>
                          <TextInputFiled
                            name="productImages"
                            data-test-id="productImagesData"
                            placeholder="Upload a Product Images (Max 10 MB, JPG,PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.selectedFileNames?.join(', ') || ''}
                            type="text"
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon onClick={this.handleProductIconClick} data-test-id="handleProductIconClick"/>
                                </InputAdornment>
                              )
                            }} helperText={undefined}                          />
                          {errors.productImages && touched.productImages && (
                      <div className="errorStyle">
                        {errors.productImages as string}
                      </div>
                    )}
                          <input
                      id="hiddenProductFileInput"
                      type="file"
                      style={{ display: 'none' }}
                      accept="image/jpeg, image/png"
                      multiple
                      data-test-id="handleProductFileChange"
                      onChange={(event) => this.handleProductFileChange(event, setFieldValue)}
                    />


                        </Box>
                        <Box className="imagePreview">
              {values.productImages?.map((file, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt={`preview-${index}`}
                  style={{
                    width: '200px',
                    height: '180px',
                    objectFit: 'cover',
                    borderRadius:"8px",
              
                  }}
                />
              ))}
            </Box>
<Box className="inputFieldsSection">
  <InputLabel className="labelClass">
    Function<span className="star">*</span>
  </InputLabel>
  <div>
    <Autocomplete
      multiple
      id="tags-checkbox"
      options={functionsList}
      data-test-id="handleFunctionSelectChange"
      disableCloseOnSelect
      value={this.state.selectedFunctions}
      onChange={(event, newValue) => this.handleFunctionSelectChange(event, newValue, setFieldValue)}
      renderTags={() => null}
      renderOption={(props, option) => {
        const isSelected = this.state.selectedFunctions.some(
          (selected) => selected.value === option.value
        );
        return (
          <MenuItem
            {...props}
            key={option.value}
            onClick={() => {
              this.toggleFunctionOption(option);
              this.handleFunctionOption(option, isSelected, setFieldValue);
            }}
            style={{ borderBottom: '1px solid #E7E5E4' }}
          >
            <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Functions for Outfits"
          onChange={(event) => {
            handleChange(event);
          }}
          onBlur={handleBlur}
          value={values.selectFunction}
          error={this.isError(touched.selectFunction, errors.selectFunction)}
          helperText={this.helperText(touched.selectFunction, errors.selectFunction)}
        />
      )}
    />
  </div>

  <Stack direction="row" spacing={1} className="chipBox">
    {this.state.selectedFunctions.map((option) => (
      <Chip
        key={option.value}
        label={option.label}
        style={{ margin: '4px' }}
        data-test-id="handleDeleteFunctionChip"
        onDelete={() => this.handleDeleteFunctionChip(option, setFieldValue)}
        deleteIcon={
          <IconButton size="small">
            <CloseIcon fontSize="small" className="closeIcon" />
          </IconButton>
        }
      />
    ))}
  </Stack>
</Box>


                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Service Location<span className="star">*</span></InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={locationData}
                              value={this.state.selectedLocations}
                              data-test-id="handleSelectLocationChange"
                              onChange={(event, newValue) => this.handleSelectLocationChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedLocations.some(selected => selected.value === option.value);
                                return (                                    
                                    <MenuItem
            {...props}
            key={option.value}
            onClick={() => {
              this.toggleLocationOption(option);
              this.handleLocationOption(option, isSelected, setFieldValue);
            }}
            style={{ borderBottom: '1px solid #E7E5E4' }}
          ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        option.label}
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  data-test-id="handleChangeLocation"
                                  placeholder="Select the location where you can deliver your products for Outfit"
                                  onChange={(event) => handleChange(event)}
                                  onBlur={handleBlur}
                                  value={values.selectedLocation}
                                  error={this.isError(touched.selectedLocation, errors.selectedLocation)}
                              helperText={this.helperText(
                          touched.selectedLocation,
                          errors.selectedLocation
                        )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox">
                            {this.state.selectedLocations.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteLocationChip"
                                onDelete={() => this.handleDeleteLocationChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>
                        </Box>
                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Available Sizes<span className="star">*</span></Typography>
                          <Button className="quesBtn">+ Add Sizes</Button>
                        </Box>
                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Size Name</InputLabel>
                            <TextInputFiled
                              name="size"
                              data-test-id="full-name-input"
                              placeholder="XS"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.size} error={this.isError(touched.size, errors.size)}
                              helperText={this.helperText(
                          touched.size,
                          errors.size
                        )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Measurements</InputLabel>
                            <TextInputFiled
                              name="measurement"
                              data-test-id="full-name-input"
                              placeholder="Enter value"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.measurement} error={this.isError(touched.measurement, errors.measurement)}
                              helperText={this.helperText(
                          touched.measurement,
                          errors.measurement
                        )}
                            />
                          </Box>

                        </Box>

                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Size Chart</InputLabel>
                          <TextInputFiled
                            name="selectedFileName"
                            data-test-id="sizeChart"
                            placeholder="Upload a Size Chart (Max 10 MB, JPG,PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.sizeChart || ''}
                            type="text"
                            error={this.isError(touched.selectedFileName, errors.selectedFileName)}
                      helperText={this.helperText(
                        touched.selectedFileName,
                        errors.selectedFileName
                      )}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon onClick={this.handleIconClick} data-test-id="handleIconClick"/>
                                </InputAdornment>
                              )
                            }}
                          />
                          <input
        id="hiddenFileInput"
        type="file"
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        data-test-id="handleFileChange"
        onChange={(event) => this.handleFileChange(event, setFieldValue)}
      />
</Box>
                        <Box className="imagePreview">
  {values.selectedFileName && (
    <img
      src={URL.createObjectURL(values.selectedFileName)}
      alt="Preview"
      style={{
        width: '200px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    />
  )}
</Box>
                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Available Color<span className="star">*</span></Typography>
                          <Button className="quesBtn">+ Add Field</Button>
                        </Box>
                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Name of The Color</InputLabel>
                            <TextInputFiled
                              name="colorName"
                              data-test-id="full-name-input"
                              placeholder="Enter Name"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.colorName} error={this.isError(touched.colorName, errors.colorName)}
                              helperText={this.helperText(
                          touched.colorName,
                          errors.colorName
                        )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Upload image</InputLabel>
                            <TextInputFiled
                              name="uploadImage"
                              data-test-id="full-name-input"
                              placeholder="Upload Image  2MB jpg,png"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.uploadImage} error={this.isError(touched.uploadImage, errors.uploadImage)}
                              helperText={this.helperText(
                          touched.uploadImage,
                          errors.uploadImage
                        )}
                            />
                          </Box>

                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available  Size</InputLabel>
                            <SelectField
                              name="colorSize"
                              data-test-id="monthCheck"
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              options={productSize}
                              placeholder="Select Size"
                              label={""} value={values.colorSize} error={Boolean(this.handleErrorText(touched.colorSize, errors.colorSize))}
                              touched={touched.colorSize}
                              helperText={this.handleErrorText(touched.colorSize, errors.colorSize)} />
                          </Box>

                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available QTY</InputLabel>
                            <TextInputFiled
                              name="availableQuantity"
                              data-test-id="full-name-input"
                              placeholder="Enter Qty"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.availableQuantity} error={this.isError(touched.availableQuantity, errors.availableQuantity)}
                              helperText={this.helperText(
                          touched.availableQuantity,
                          errors.availableQuantity
                        )}
                            />
                          </Box>
                        </Box>
                          <Box className="faqAskSection">
                          <Typography className="faqAsk">Custom Field</Typography>
                          <Button className="quesBtn">+ Add Color</Button>
                        </Box>

                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Title</InputLabel>
                            <TextInputFiled
                              name="customTitle"
                              data-test-id="full-name-input"
                              placeholder="Enter Title"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.customTitle} error={this.isError(touched.customTitle, errors.customTitle)}
                              helperText={this.helperText(
                          touched.customTitle,
                          errors.customTitle
                        )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Description</InputLabel>
                            <TextInputFiled
                              name="customDescription"
                              data-test-id="full-name-input"
                              placeholder="Type something..."
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.customDescription} error={this.isError(touched.customDescription, errors.customDescription)}
                              helperText={this.helperText(
                          touched.customDescription,
                          errors.customDescription
                        )}
                            />
                          </Box>
                           <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Coupons<span className="star">*</span></InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={coupons}
                              data-test-id="handleSelectChange"
                              onChange={(event, newValue) => this.handleSelectChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedColors.some(selected => selected.value === option.value);
                                return (
                                  <MenuItem {...props} key={option.value} onClick={() => {this.toggleOption(option); this.handleCouponOption(option, isSelected, setFieldValue)}} style={{ borderBottom: "1px solid #E7E5E4" }}>
                                    <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: "20px" }}>
                                          {option.label}
                                        </div>
                                      }
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select the coupon"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="selectColor"
                                  value={values.selectColor}
                                  error={this.isError(touched.selectColor, errors.selectColor)}
                              helperText={this.helperText(
                          touched.selectColor,
                          errors.selectColor
                        )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox" data-test-id="chipBoxData">
                            {this.state.selectedColors.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteChip"
                                onDelete={() => this.handleDeleteChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>
                        </Box>

                        <Box className="faqAskSection">
                          <Typography className="faqAsk">Frequently asked questions</Typography>
                          <Button className="quesBtn">+ Add Questions</Button>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Questions<span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="businessName"
                            data-test-id="full-name-input"
                            placeholder="Q1: Enter Your questions"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={""} helperText={undefined}
                          />
                          <Box className="ansSection">
                            <img src={arrowImg} alt="arrow" className="arrowStyle" />
                            <TextInputFiled
                              name="businessName"
                              data-test-id="full-name-input"
                              placeholder="A: Enter Response"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={""} helperText={undefined}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="Btns">
                        <Button className="cancelBtn">Cancel</Button>
                        <Button className="proceedBtn" type="submit">Proceed</Button>

                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </AddCatlogueMainStyle>
        </CustomContainer>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1280px)": {
    maxWidth: "95%",
  },
});

const AddCatlogueMainStyle = styled(Box)({
  padding: "32px 0 24px",
  overflowX: 'hidden',
  "& .addCatlogueText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    color: "#292524"
  },
  "& .dividerStyle": {
    backgroundColor: "#A8A29E",
    height: "1px",
    marginTop: "24px",
    marginBottom: "30px",
    width: "94%"
  },
  "& .mainCatalogueBox": {
    border: "1px solid #D6D3D1",
    borderRadius: '16px',
    backgroundColor: "#FFFFFF",
    padding: "30px 28px"
  },
  "& .headText": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    color: "#292524",
  },
  "& .labelClass": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
  },
  "& .star": {
    color: "#DC2626 !important",
  },
  "& .inputFirstSection": {
    display: 'flex',
    width: "100%",
    gap: "26px"
  },
  "& .inputFieldsSection": {
    width: '100%'
  },
  "& .mainForm": {
    width: '100%',
    paddingTop: "28px",
    paddingBottom: "80px",
    display: "flex",
    flexDirection: 'column',
    gap: "32px"
  },
  "& .cancelBtn": {
    color: "#94A3B8",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#E2E8F0",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#E2E8F0"
    }
  },
  "& .proceedBtn": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .Btns": {
    display: "flex",
    width: '100%',
    justifyContent: "flex-end",
    gap: "24px"
  },
  "& .selectOption": {
    color: "#0F172A",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
  },
  "& .Mui-checked": {
    color: "#6200EA !important",
  },
  "& .MuiCheckbox-root": {
    borderRadius: "6px !important",
  },
  "& .faqAskSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  "& .faqAsk": {
    color: "#292524",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
  },
  "& .quesBtn": {
    cursor: "pointer",
    color: "#7F1187",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    textTransform: "capitalize"
  },
  "& .ansSection": {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: "100%",
    marginTop: "-15px",
    "& > *:nth-child(2)": {
      width: "100%",
    },
  },
  "& .arrowStyle": {
    marginTop: "-30px",
    marginLeft: "15px"
  },
  "& .MuiChip-root": {
    border: "2px solid #CA1A7D",
    backgroundColor: "transparent",
    padding: '6px, 8px, 6px, 12px'
  },
  "& .MuiChip-label": {
    color: "#292524",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& .closeIcon": {
    color: "black"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .chipBox": {
    marginTop: "20px"
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .imagePreview":{
    display:"flex",
    gap:"8px",
    width:"80%",
    flexWrap:"wrap"
  },
  "& .errorStyle":{
    margin:" 8px 0px 0px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color:"rgb(211, 47, 47)"
  },
  "& .inputIcons":{
    cursor:"pointer"
  }
});
// Customizable Area End
