import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";

export interface ICarouselItems {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    position: number;
    img_vid_url: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
  };
}

export interface ISubject {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IFaqQuestionAnswer {
  type: string;
  id: string;
  attributes: {
    id: number;
    answer: string;
    question: string;
    admin_users_id: number;
  };
}

export interface IWhoWeAre {
  id: string;
  type: string;
  attributes: {
    id: number;
    heading: string;
    description: string;
    img_video: {
      url: string;
      id: number;
      date: string;
      type: "image" | "video";
    };
  };
}


export type WebResponseJsonForContactUs = {
  errors: { contact: string[] }[];
} & {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      name: string;
      phone_number: string;
      description: string;
      subject_id: number;
    };
  };
};

export type WebResponseJsonForNewSletter = {
  errors: { news_letter: string[] }[];
} & {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      name: string;
      subscribe: boolean;
    };
  };
};

export interface IWhyChooseUs {
  id: string;
  type: string;
  attributes: {
    id: number;
    description_one: string;
    description_two: string;
    description_three: string;
    description_four: string;
    description_five: string;
    description_six: string;
    image: {
      url: string;
    };
  };
}

export interface IHowItWorks {
  id: string;
  type: string;
  attributes: {
    id: number;
    heading: string;
    description: string;
    image: {
      url: string;
    };
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  carouselItem: ICarouselItems[];
  isLoading: boolean;
  subjectList: ISubject[];
  isEmailAlreadyUsed: boolean;
  faqQuestionAnswer: IFaqQuestionAnswer[];
  whoWeAreData: IWhoWeAre[];
  whyChooseUsData: IWhyChooseUs[];
  howItWorkData: IHowItWorks[];
  errorMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      carouselItem: [],
      isLoading: false,
      subjectList: [],
      isEmailAlreadyUsed: false,
      faqQuestionAnswer: [],
      whoWeAreData: [],
      whyChooseUsData: [],
      howItWorkData: [],
      errorMessage:""
    };
    this.formikContactRef = React.createRef();
    this.formikNewSletterRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.carouselItemApiCallId:
          this.setState({
            carouselItem: webResponseJson.data,
            isLoading: false,
          });
          break;
        case this.subjectApiCallId:
          this.setState({
            subjectList: webResponseJson,
            isLoading: false,
          });
          break;
        case this.contactUsApiCallId:
          this.handleContactUsApiResponse(webResponseJson);
          break;
        case this.newSletterApiCallId:
          this.handleNewSletterApiResponse(webResponseJson);
          break;
        case this.faqQuestionAnswerApiCallId:
          this.setState({
            isLoading: false,
            faqQuestionAnswer: webResponseJson.data,
          });
          break;
        case this.whoWeAreApiCallId:
          this.setState({
            isLoading: false,
            whoWeAreData: webResponseJson.data,
          });
          break;
        case this.whyChooseUsApiCallId:
          this.setState({
            isLoading: false,
            whyChooseUsData: webResponseJson.data,
          });
          break;
        case this.howItWorkApiCallId:
          this.setState({
            isLoading: false,
            howItWorkData: webResponseJson.data,
          });
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formikContactRef: any;
  formikNewSletterRef: any;
  carouselItemApiCallId: string = "";
  contactUsApiCallId: string = "";
  faqQuestionAnswerApiCallId: string = "";
  subjectApiCallId: string = "";
  howItWorkApiCallId: string = "";
  newSletterApiCallId: string = "";
  whoWeAreApiCallId: string = "";
  whyChooseUsApiCallId: string = "";

  async componentDidMount() {
    this.getCarouselItems();
    this.getSubjectList();
    this.getFaqQuestionAnswer();
    this.getWhoWeAreData();
    this.getWhyChooseUsData();
    this.getHowItWorkData();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  redirectToVendorPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VendorSignup"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getCarouselItems = async () => {
    this.setState({ isLoading: true });
    this.carouselItemApiCallId = await apiCall({
      endPoint: "bx_block_upload_media/carousel_items",
      method: "GET",
      contentType: "application/json",
    });
  };

  getSubjectList = async () => {
    this.setState({ isLoading: true });
    this.subjectApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/subjects",
      method: "GET",
      contentType: "application/json",
    });
  };

  contactUsApiIntegration = async (values: {
    name: string;
    email: string;
    subject_id: string;
    phone_number: string;
    description: string;
  }) => {
    this.setState({ isLoading: true });
    this.contactUsApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/contacts",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({
        data: {
          name: values.name,
          email: values.email,
          subject_id: values.subject_id,
          phone_number: `+91${values.phone_number}`,
          description: values.description,
        },
      }),
    });
  };

  handleContactUsApiResponse = (webResponseJson: WebResponseJsonForContactUs) => {
    if (webResponseJson.data) {
      toast.success("Thank you for contacting us! We will get back to you shortly.");
      if (this.formikContactRef.current) {
        this.formikContactRef.current.resetForm();
      }
      this.setState({ errorMessage: "" });
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].contact[0];
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };
  

  newSletterApiIntegration = async (values: {
    name: string;
    email: string;
  }) => {
    this.setState({ isLoading: true });
    this.newSletterApiCallId = await apiCall({
      endPoint: "bx_block_contact_us/news_letters",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({
        data: {
          name: values.name,
          email: values.email,
          subscribe: true,
        },
      }),
    });
  };

  handleNewSletterApiResponse = (webResponseJson: WebResponseJsonForNewSletter) => {
    if (webResponseJson.data) {
      toast.success("Thank you for subscribing to our newsletter.");
      if (this.formikNewSletterRef.current) {
        this.formikNewSletterRef.current.resetForm();
      }
      this.setState({ isEmailAlreadyUsed: false, errorMessage: "" });
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].news_letter[0];
      if (errorMessage === "Email has already been taken") {
        this.setState({ isEmailAlreadyUsed: true, errorMessage: "This email is already used for newsletter" });
      } else {
        this.setState({ isEmailAlreadyUsed: false, errorMessage });
      }
    }
    this.setState({ isLoading: false });
  };
  
  handleChangeIsEmailAlreadyUsed = () => {
    this.setState({ isEmailAlreadyUsed: false });
  };

  getFaqQuestionAnswer = async () => {
    this.setState({ isLoading: true });
    this.faqQuestionAnswerApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/faqs`,
      method: "GET",
    });
  };

  getWhoWeAreData = async () => {
    this.setState({isLoading: true});
    this.whoWeAreApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/who_we_ares`,
      method: "GET",
    });
  };

  getWhyChooseUsData = async () => {
    this.setState({ isLoading: true });
    this.whyChooseUsApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/why_choose_us`,
      method: "GET",
    });
  };

  getHowItWorkData = async () => {
    this.setState({ isLoading: true });
    this.howItWorkApiCallId = await apiCall({
      endPoint: `bx_block_landingpage2/how_it_works`,
      method: "GET",
    });
  };

  // Customizable Area End
}
