import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React,{ RefObject } from "react";

// Customizable Area Start
export interface ColorOption {
  label: string;
  value: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface StateType {
  selectedColors:ColorOption[];
  selectedFunctions:ColorOption[];
  selectedLocations: ColorOption[];
  isLoading:boolean;
  formData:{
    productName:string;
    skuCode:string;
    productCategory:string;
    productDescription:string;
    fabricDetails:string;
    price:string, 
        availableQuantity:string,
        selectColor:string,
        selectFunction:string,
        selectedLocation:string,
        selectedFileName:null,
        selectedFileNames: string[];
        sizeChart:string;
  productImages: File[];
  customTitle:string,
  customDescription:string,
  size:string,
  measurement:string,
  colorName:string,
  availableSize:string,
  uploadImage:string,
  colorSize:string
  }
  // Customizable Area Start

  // Customizable Area End
}

interface CustomTypeData {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyData;
  };
  endPoint: string;
}

interface CustomTypeBodyData {
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    gender: string;
    country: string;
    industry: string;
    message: string;

  };
  type: string;
}

export interface CountriesList {
  Countries: Array<Countries>;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCatalogueFormController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  fileInputRef:RefObject<HTMLDivElement[]>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInputRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      selectedColors:[],
      selectedFunctions:[],
      selectedLocations:[],
      isLoading:false,
      formData:{
        productName:"",
        skuCode:"",
        productCategory:"",
        productDescription:"",
        fabricDetails:"",
        price:"", 
        availableQuantity:"",
        selectColor:"",
        selectFunction:"",
        selectedFileName:null,
        selectedLocation:"",
        selectedFileNames: [],
        sizeChart:"",
  productImages:[],
  customTitle:"",
  customDescription:"",
  size:"",
  measurement:"",
  colorName:"",
  availableSize:"",
  uploadImage:"",
  colorSize:""
      },
      // Customizable Area Start

      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Api response
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedColors: newValue });
    setFieldValue('selectColor', newValue);
  };
  handleDeleteChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedCoupon = this.state.selectedColors.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedColors: newSelectedCoupon });
    setFieldValue('selectColor', newSelectedCoupon);
  };
  toggleOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedColors } = prevState;
      const isSelected = selectedColors?.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedColors: selectedColors?.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedColors: [...selectedColors, optionToToggle],
        };
      }
    });
  };
  handleCouponOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedCoupons = isSelected
    ? this.state.selectedColors.filter(
        (selected) => selected.value !== option.value
      )
    : [...this.state.selectedColors, option];
  this.setState({ selectedColors: newSelectedCoupons });
  setFieldValue('selectColor', newSelectedCoupons);
  }
  handleFunctionSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedFunctions: newValue });
    setFieldValue('selectFunction', newValue);
  };
  handleDeleteFunctionChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = this.state.selectedFunctions.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedFunctions: newSelectedFunctions });
    setFieldValue('selectFunction', newSelectedFunctions);
  };
  toggleFunctionOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedFunctions } = prevState;
      const isSelected = selectedFunctions.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedFunctions: selectedFunctions.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedFunctions: [...selectedFunctions, optionToToggle],
        };
      }
    });
  };
  handleFunctionOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = isSelected
    ? this.state.selectedFunctions.filter(
        (selected) => selected.value !== option.value
      )
    : [...this.state.selectedFunctions, option];
  this.setState({ selectedFunctions: newSelectedFunctions });
  setFieldValue('selectFunction', newSelectedFunctions);
  }
  handleSelectLocationChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      this.setState({ selectedLocations: newValue });
      setFieldValue('selectedLocation', newValue);
    };

  handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = this.state.selectedLocations.filter(
      (item) => item.value !== optionToDelete.value
    );
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selecredLocation', newSelectedLocation);
  };
  toggleLocationOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedLocations } = prevState;
      const isSelected = selectedLocations.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedLocations: selectedLocations.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedLocations: [...selectedLocations, optionToToggle],
        };
      }
    });
  };
  handleLocationOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = isSelected
                ? this.state.selectedLocations.filter(
                    (selected) => selected.value !== option.value
                  )
                : [...this.state.selectedLocations, option];
              this.setState({ selectedLocations: newSelectedLocation });
              setFieldValue('selectedLocation', newSelectedLocation);
  }
  handleErrorText = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && errors
  }
  handleIconClick = () => {
    const inputElement = document.getElementById('hiddenFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleProductIconClick = () => {
    const inputElement = document.getElementById('hiddenProductFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setFieldValue('selectedFileName', null);
        setFieldValue('sizeChart', '');
      } else {
        setFieldValue('selectedFileName', file);
        setFieldValue('sizeChart', file.name);
      }
    }
  };
  handleProductFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const fileNames = fileArray.map((file) => file.name);
      setFieldValue('productImages', fileArray);
      setFieldValue('selectedFileNames', fileNames);
    }
  };
  // Customizable Area End
}