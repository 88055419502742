import React, { useEffect, useRef, useState } from "react";
import { styled, Box, Typography, Button, Container } from "@mui/material";

import { NavLink } from "react-router-dom";

const facebookLogo = require("../src/assets/facebook.svg");
const twitterLogo = require("../src/assets/twitter.svg");
const instagramLogo = require("../src/assets/instagram.svg");
const youtubeLogo = require("../src/assets/youtube.svg");
const pinterestLogo = require("../src/assets/pinterest.svg");
const footerLogo = require("../src/assets/footer_logo.png");

const companyLink = [
  { label: "Home", path: "/" },
  { label: "About us", path: "/" },
  { label: "Terms of services", path: "/" },
  { label: "Privacy policy", path: "/" },
  { label: "Contact us", path: "/contact-us" },
];

const followUsLink = [
  {
    label: "Facebook",
    path: "https://www.facebook.com/login/",
    icon: facebookLogo,
  },
  { label: "Twitter", path: "https://x.com/i/flow/login", icon: twitterLogo },
  {
    label: "Instagram",
    path: "https://www.instagram.com/",
    icon: instagramLogo,
  },
  { label: "Youtube", path: "https://youtube.com/", icon: youtubeLogo },
  {
    label: "Pinterest",
    path: "https://in.pinterest.com/",
    icon: pinterestLogo,
  },
];

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Chat from "../../blocks/chat/src/Chat.web";
import { useBlockHelpers } from "../../../packages/blocks/utilities/src/hooks/useBlockHelpers";
import { useRunEngine } from "../../../packages/blocks/utilities/src/hooks/useRunEngine";
import { Message } from "../../../packages/framework/src/Message";
import MessageEnum, { getName } from "../../../packages/framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

interface IFooter {
  className?: string;
}

const Footer: React.FC<IFooter> = ({ className }) => {
  const year = new Date().getFullYear();

  const getCarouselCallId = useRef("");

  const { extractNetworkResponse } = useBlockHelpers();

  const {
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const handleLinkClick = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  const [openChatModal, setOpenChatModal] = useState(false);
  const [value, setValue] = useState<any>(null);
  const handleChatOpenClose = () => {
    setOpenChatModal((prevData) => !prevData);
  };

  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
  ];

  const getCarouselData = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": "application/json",
      token
    };
    sendNetworkRequest(
      getCarouselCallId,
     "GET",
     "/bx_block_landingpage2/why_choose_us/show_contact_info",
      header
    );
  };

  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));
    getCarouselData();
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        if (
          apiRequestCallId === getCarouselCallId.current &&
          responseJson?.data
        ) {
          setValue(responseJson.data)
        }
      }
    }
    
  };
  return (
    <CustomContainer className={className}>
      <FooterStyle>
        <HorizontalLine />
        <Box className="text-image-container">
          <Box className="left-text-container">
            <Box className="contact-us-section">
              <Typography variant="h6" className="footer-title">
                Contact us to get best deals
              </Typography>
              <Box className="vendor-user-container">
                <Box>
                  <Typography variant="h6" className="vendor-title">
                    For vendors
                  </Typography>
                  <Typography className="vendor-description">
                   {value?.attributes.vendor_email}
                  </Typography>
                  <Typography className="vendor-description">
                  +91 {value?.attributes.vendor_phone_number}
                  </Typography>
                </Box>
                <Box>
                  <VerticalLine />
                </Box>
                <Box>
                  <Typography variant="h6" className="vendor-title">
                    For users
                  </Typography>
                  <Typography className="vendor-description">
                  {value?.attributes.user_email}
                  </Typography>
                  <Typography className="vendor-description">
                  +91 {value?.attributes.user_phone_number}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" className="footer-title">
                Registered address
              </Typography>
              <Typography className="address">
              {value?.attributes.address}
              </Typography>
            </Box>
            <Box className="company-section">
              <Typography variant="h6" className="footer-title">
                Company
              </Typography>
              <Box className="link-container">
                {companyLink.map((data, index) => (
                  <NavLink to={data.path} className="company-link" key={index}>
                    {data.label}
                  </NavLink>
                ))}
              </Box>
            </Box>
            <Box className="follow-us-section">
              <Typography variant="h6" className="footer-title">
                Follow us on
              </Typography>
              <Box className="follow-link-container">
                {followUsLink.map((data, index) => (
                  <Typography
                    key={index}
                    className="follow-link"
                    onClick={() => handleLinkClick(data.path)}
                  >
                    <img
                      src={data.icon}
                      alt={data.label}
                      className="social-icon"
                    />
                    {data.label}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className="right-image-container">
            <img src={footerLogo} alt="footer" className="footer-image" />
            <Box position="relative">
              <Button
                className="chat-with-us-button"
                onClick={handleChatOpenClose}
              >
                <ChatBubbleOutlineIcon /> Chat with us
              </Button>
              {openChatModal && (
                <Box className="chat-modal-design-container">
                  <Chat
                    id="chat"
                    handleChatClose={handleChatOpenClose}
                    data-test-id="handleChatClose"
                    navigation={undefined}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <HorizontalLine />
        <Typography className="copyright-text">{year} Happiffie</Typography>
      </FooterStyle>
    </CustomContainer>
  );
};

export default Footer;

const FooterStyle = styled("footer")(({ theme }) => ({
  padding: "80px 0",
  zIndex: "100",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "50px 0",
  },
  "& .chat-modal-design-container": {
    zIndex: 200,
    position: "absolute",
    bottom: "75px",
    right: "0px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      left:"-80%"
    },
  },
  "& .vendor-user-container": {
    display: "flex",
    gap: "30px",
    marginBottom: "46px",
    [theme.breakpoints.down("lg")]: {
      gap: "20px",
    },
  },
  "& .copyright-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    padding: "16px 27px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0px 0px",
    }
  },
  "& .contact-us-section": {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  "& .address": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    maxWidth: "253px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "unset",
    },
  },
  "& .footer-title": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#292524",
    marginBottom: "30px",
  },
  "& .chat-with-us-button": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#FFFFFF",
    backgroundColor: "#7F1187 !important",
    borderRadius: "30px",
    gap: "8px",
    padding: "10px 24.48px",
    textTransform: "none",
    width: "fit-content",
  },
  "& .text-image-container": {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .right-image-container": {
    display: "flex",
    flexDirection: "column",
    gap: "177px",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      gap: "30px",
      alignItems: "center",
    },
    "& .footer-image": {
      width: "230px",
      height: "176px",
    },
  },
  "& .left-text-container": {
    padding: "40px 0 32px",
    display: "flex",
    gap: "100px",
    [theme.breakpoints.down("lg")]: {
      gap: "50px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "30px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  "& .follow-link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  "& .company-link": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#292524",
    textDecoration: "none",
    "&:hover": {
      color: "rgb(127, 17, 135)",
    },
  },
  "& .follow-link": {
    fontFamily: "Poppins",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "rgb(41, 37, 36)",
    textDecoration: "none",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingLeft: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
    "&:hover": {
      color: "rgb(127, 17, 135)",
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0px",
    },
  },
  "& .vendor-description": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#292524",
  },
  "& .vendor-title": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#292524",
  },
}));

const VerticalLine = styled(Box)({
  width: "1px",
  height: "100%",
  backgroundColor: "#A8A29E",
});

const HorizontalLine = styled("hr")({
  margin: "0",
  backgroundColor: "#A8A29E",
  borderBottom: "1px !important",
});

const CustomContainer = styled(Container)({
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
