import React from "react";
// Customizable Area Start
import { Box, Typography , Button, Table,TableHead,TableCell,TableRow,TableBody} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import { Image1, Image2, Image3, Image4, Image5 } from "./assets";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: any) {
    let value = item.attributes;
    return (
      <>
        <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
          <Box sx={webStyle.ImgContainer}>
            <img style={webStyle.productImg} src={value.images?.[0].url} />
          </Box>
          <Box sx={webStyle.detailContent}>
            <Typography variant="h6">{value.name}</Typography>
            <Typography variant="h6">{value.price}</Typography>
            <Typography variant="h6">{value.average_rating}</Typography>
          </Box>
        </Box>
      </>
    );
  }

  productsData = () => {
    return (
      [
        {
          images:Image1,
          category: 'Haldi ceremony collection',
          name:"Yellow mirror work Lehenga",
          price: " ₹86,000",
          status: "Available",
        },    {
          images:Image2,
          category: 'Haldi ceremony collection',
          name:"Yellow mirror work Lehenga",
          price: " ₹86,000",
          status: "Unavailable",
        },
        {
          images:Image3,
          category: 'Haldi ceremony collection',
          name:"Yellow mirror work Lehenga",
          price: " ₹86,000",
          status: "Available",
        },
        {
          images:Image4,
          category: 'Haldi ceremony collection',
          name:"Yellow mirror work Lehenga",
          price: " ₹86,000",
          status: "Available",
        },
        {
          images:Image5,
          category: 'Haldi ceremony collection',
          name:"Yellow mirror work Lehenga",
          price: " ₹86,000",
          status: "Available",
        }
      ]
    )
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Box>
          <Typography style={{ fontSize: "1.3rem", fontWeight: 700, margin: "0px 2.5rem" }}>Upload Catalogue</Typography>
        </Box>
        <hr style={{ color: "#A8A29E", width: "95.5vw" }}></hr>
        <Box sx={webStyle.contentHeading}>
          <Typography style={{ fontSize: "16px", color: "#44403C", fontFamily: "Poppins" }}>Have unique products to sell? Choose from the options below</Typography>
          <Button onClick={this.goTaddCatalogue} style={{ width: "215px", height: "45px", background: "purple", color: "white", fontFamily: "poppins", borderRadius: "45px", fontWeight: 700, textTransform: "none", fontSize: "16px" }}>Add Catalogue</Button>
          <Typography style={{ fontFamily: "poppins", fontSize: "16px" }}>Overview</Typography>
          <Box sx={webStyle.uploadBox}>
            <Box sx={webStyle.uploadBox2}>
              <Typography style={{ fontFamily: "poppins", fontSize: "16px", color: "#78716C" }}>Total uploads done</Typography>
              <Typography style={{ fontWeight: 700, fontSize: "1.5rem" }}>20</Typography>
            </Box>

          </Box>

          <Box sx={webStyle.mainContainer}>
            <Table aria-label="a dense table" style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid lightgrey' }}>
              <TableHead style={{ borderLeft: '1px solid transparent', borderRight: "1px solid transparent", borderTop: "1px solid transparent" }}>
                <TableRow>
                  <TableCell style={{ fontFamily: "poppins" }}>Images</TableCell>
                  <TableCell style={{ fontFamily: "poppins" }}>Details</TableCell>
                  <TableCell style={{ fontFamily: "poppins" }}>Price</TableCell>
                  <TableCell style={{ fontFamily: "poppins" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "1px solid lightgrey" }}>
                {this.productsData().map((product, index) => (
                  <TableRow key={index} style={{ border: "1px solid transparent" }}>
                    <TableCell>
                      <img src={product.images} alt={product.name} style={{ width: '203px', height: '213px' }} />
                    </TableCell>
                    <TableCell>
                      <Box sx={webStyle.contentItems}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{product.name}</Typography>
                        <Box>
                          <Typography style={{ fontFamily: "poppins" }}>
                            Categories: <span style={{ textDecoration: 'underline', color: 'purple', fontFamily: "poppins", fontWeight: 400, fontSize: "14px" }}>{product.category}</span>
                          </Typography>
                          <Box sx={{ display: 'flex', gap: '8px', mt: 1 }}>
                            <Button sx={webStyle.button}>Edit</Button>
                            <Button sx={webStyle.button}>Delete</Button>
                            <Button sx={webStyle.button}>View</Button>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ paddingBottom: "11rem", fontFamily: "poppins", fontSize: "14px", fontWeight: 400 }}>{product.price}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ paddingBottom: "11rem", fontFamily: "poppins" }}>{product.status}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </HeaderVendorFooter>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
   borderRadius:"10px",
   border:"1px solid lightgrey",
   borderBottom:"1px solid transparent"
  },
  button:{
     color: 'black', 
     textTransform: 'none' ,
     fontFamily:"poppins",
     fontSize:"14px",
     fontWeight:400
  },
  contentItems:{
    display:"flex", 
    flexDirection:"column",
    gap:"1.5rem",
    height:"98px",
    paddingBottom:"7rem"
  },
  content:{
    display:"flex", 
    flexWrap:"wrap",
    padding:"1.5rem",
    justifyContent:"space-between",
    alignItems:"center"
  },
  innerHeader:{
    display:"flex",
    justifyContent:"space-between",
    height:"2rem",
    padding:"1rem",
    paddingRight:"13rem",
    alignItems:"center",
    borderBottom:"1px solid black"
  },
  contentHeader:{
    maxWidth:"100vw",
    minHeight:"auto",
    border:"1px solid lightgrey",
  },
  uploadBox:{
    background:"white",
    maxWidth:"32%",
    height:"80px",
    border:"1px solid lightGrey",
    borderRadius:"1rem"
  },
  uploadBox2:{
  padding:"1rem"
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  contentHeading:{
    margin: "0px 2.5rem",
    display:"flex", 
    flexDirection:"column",
    gap:"2rem"
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  // button:{
  //   paddingLeft:"2.5rem"
  // },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
